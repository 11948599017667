.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.not-included {
  border: 1px solid red;
}
/* .carousel .control-dots {
  position: absolute;
  bottom: -20px;
  margin: 10px 0;
  padding: 0;
  text-align: center;
  width: 100%;
  z-index: 1;
} */
.icon{
    flex-grow: 0;
    width: 30%;
    height: 50px;
}
.carousel .control-dots {
  position: absolute;
  bottom: -20px !important;
  margin: 10px 0;
  padding: 0;
  text-align: center;
  width: 100%;
  z-index: 1;
}

.carousel.carousel-slider .control-arrow {
  background: red;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  background: #2568b9 !important;
  width: 50px !important;
  height: 50px !important;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);
  top: 50% !important;
  border: 2px solid #010d4c !important;
}
