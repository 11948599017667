@import "./common/assets/css/style.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.custom-navbar:has(.large) {
  min-width: 600px;
}
.custom-navbar:has(.small){
  min-width: 300px;
}



.custom-section-images.illustration-holder {
  width: 100%;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
}

.styles_scroll-to-top__2A70v {
  border-radius: 6px;
  z-index: 9999;
}

.faqsection {
  max-height: 600px;
  overflow-y: auto;
}

.faqsection .accordion .accordion__item {
  background: #ffffff;
  box-shadow: 0px 15px 30px rgb(68 109 255 / 4%);
  border-radius: 7px;
  border: none;
  padding: 16px;
}

.faqsection .accordion .accordion__item .accordion__heading {
  color: var(--heading);
  font-weight: 600;
  transition: all 0.5s linear;
}

.faqsection .accordion .accordion__item .accordion__panel {
  font-size: 17px;
  line-height: 30px;
  transition: all 0.5s linear;
}

.faqsection .accordion .accordion__button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.faqsection .accordion .accordion__button::after {
  display: inline-block;
  content: "";
  height: 10px;
  width: 10px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(225deg);
  transition: all 0.25s linear;
}

.faqsection .accordion .accordion__button[aria-expanded="true"]::after,
.faqsection .accordion .accordion__button[aria-selected="true"]::after {
  transform: rotate(45deg);
}

.bullet-points {
  padding-left: 2.5rem;
}
.bullet-points > li:before {
  display: inline-block;
  content: "";
  height: 10px;
  width: 10px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(315deg);
  transition: all 0.25s linear;
  margin-right: 1.5rem;
  position: absolute;
  left: -2rem;
  top: 1rem;
  color: var(--prime-one);
}

.bullet-points > li {
  position: relative;
}

.fs-32 {
  font-size: 2rem;
}

.fs-16 {
  font-size: 16px;
  line-height: 1.5;
}


.fs-14 {
  font-size: 14px;
  line-height: 1.4;
}

.job-card {
  background-color: #f0f8ff;
  position: static;
  top: 90px;
  align-self: flex-start;
  height: 100vh;
}

.job-card .card-content {
  height: calc(100vh - 160px);
  overflow: auto;
  border: 1px solid #a7cfff;
  border-radius: 10px;
  background: #e5f1ff;
  border-top-left-radius: 0;
}

.job-card .react-tabs__tab-panel {
  flex-grow: 1;
}

.job-card .job-card-tabs {
  border: 1px solid #f5f5f5;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 160px);
  width: 30%;
}
.ad-space{
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 100;
}
.popup{
  padding-inline-start: 1.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-start-start-radius: 9999px;
  border-end-start-radius: 9999px;
  cursor: pointer;
  width: 12rem;
}


.job-card .job-card-tabs > li.react-tabs__tab--selected {
  background: #a7cfff;
  border: 0;
  border-top-right-radius: 0;
}

.job-card .react-tabs__tab-panel {
  width: 0;
}

.carousel .control-dots .dot {
  background-color: var(--prime-one);
}

.section-space {
  padding-top: 60px;
  padding-bottom: 60px;
}

.fs-16px {
  font-size: 16px !important;
}

.hero-banner-five {
  background: url(./common/assets/images/home/bannerimg_1.svg) no-repeat right
    center;
  background-size: 50%;
  height: 100vh;
}

@media screen and (max-width: 992px) {
  .hero-banner-five {
    background: #2568b96e !important;
  }
}
